/**
 If checkout and order page are on different subdomains, we can't access localStorage for nonprofitId/selectionId
 We can make post-transaction work by copying a "cartID"-style cookie to the expected localStorage key on the current
 domain, which will allow the post-transaction widgets to send that to the backend which can restore the selection
 from the cart.

 This will also work if we don't have a cart cookie for some other reason, although it is not directly integrated
 with the host site's concept of a cart (e.g., line items and expiration dates).

 Note: Use a module import rather than a self-executing script so that we can set cookies on the host domain
 rather than our 3rd party CDN domain, and customize the options via arguments.

 Usage:
 <script type="module">
 import { createSession } from 'https://...';
 createSession({domain: 'mystore.com'})
 </script>
 */

import { v4 as uuid } from "@lukeed/uuid/secure";
import { deleteCookieValue, getCookieValue, setCookieValue } from "../../shared/cookies";
import { createScopedLocalStorage } from "../../shared/local-storage";

const DEFAULT_COOKIE_NAME = "beam_session";

export async function createSession({
  cookieName = DEFAULT_COOKIE_NAME,
  domain = window.location.hostname, // domain or subdomain to set cookie on
  apiKey = "", // optional unless scoping localStorage to api key
} = {}) {
  const existingValue = await getCookieValue(cookieName);

  if (existingValue) {
    return; // do nothing
  }

  const cookieValue = uuid();

  const expires = Date.now() + 30 * 24 * 60 * 60 * 1000; // 30 days

  await setCookieValue({ name: cookieName, value: cookieValue, domain, path: "/", expires });

  const beamLocalStorage = createScopedLocalStorage({ apiKey });
  beamLocalStorage.setItemJson("cart", { cartId: cookieValue });
}

export async function endSession({
  cookieName = DEFAULT_COOKIE_NAME,
  domain = window.location.hostname, // domain to remove cookie from
  apiKey = "", //  optional unless scoping localStorage to api key
} = {}) {
  const cookieValue = await getCookieValue(cookieName);

  if (!cookieValue) {
    return; // do nothing
  }

  // copy cookie value into localStorage for widgets to use
  const beamLocalStorage = createScopedLocalStorage({ apiKey });
  beamLocalStorage.setItemJson("cart", { cartId: cookieValue });

  // delete cookie to end session (will generate new one on next visit)
  await deleteCookieValue({ name: cookieName, domain });
}
